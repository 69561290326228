import React from "react";

import Layout from "../components/Layout";

import Scroll from "../components/Scroll";

import pic1 from "../assets/images/pic01.jpg";
import pic2 from "../assets/images/pic02.jpg";
import pic3 from "../assets/images/pic03.jpg";
import config from "../../config";
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          <li>
            <Scroll type="id" element="two">
              <a href="/#" className="button primary">
                Explore
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="two">
        <a href="#two" className="more">
          Projects
        </a>
      </Scroll>
    </section>

    <section id="two" className="wrapper alt">
      <section className="spotlight style1">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2>Komitet Beton hala website</h2>
          <p>
            The epicenter of a good time in the Serbian capital. Ever since they
            opened with them, you can relax and enjoy delicious food and good
            drinks, all of course with a live gig of the best bends in our city.
          </p>
          <a href="https://komitet.rs/" target="_blank">See website</a>
        </div>
      </section>

      <section className="spotlight style2">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2>Moj Grad Game room website</h2>
          <p>
            Moj Grad game room is a place to play, celebrate, and have fun for
            kids and teens, and rest and relaxation for seniors.
          </p>
          <a href="http://mojgrad.co.rs/" target="_blank">See website</a>
        </div>
      </section>
      <section className="spotlight style1">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <h2>Ardeo Solutions website</h2>
          <p>
            Company that provide end-to-end solutions from strategy and planning
            to website design and development, as well as extensive security,
            scalability, performance and long-term guidance and maintenance.
          </p>
          <a href="https://ardeo.solutions" target="_blank">See website</a>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style4 special">
      <div className="inner">
        <header className="major">
          <p>
            Energetic Web Designer with one year experience creating and
            maintaining functional, attractive, and responsive websites. Clear
            understanding of modern technologies and best design practices.
          </p>
        </header>
        <ul className="features">
          <li className="">
            <h3>Services</h3>
          </li>
          <li className="">
            <h3>Tools and Methods</h3>
          </li>
          <li className="icon solid fa-lightbulb">
            <h3>Brand Design</h3>
            <p>
              My work is the culmination of creative visions, innovative ideas
              and full commitment.
            </p>
          </li>
          <li className="icon brands fa-figma">
            <h3>Web design</h3>
            <h4 className="icon solid">Figma</h4>
            <h4 className="icon solid">Webflow</h4>
          </li>
          <li className="icon solid fa-desktop">
            <h3>UI/UX Design</h3>
            <p>
              I craft UI/UX Design that works and solves real problems via
              products and delightful user experience.
            </p>
          </li>
          <li className="icon brands fa-adobe">
            <h3>Graphic design</h3>
            <h4 className="icon solid">Adobe Photoshop</h4>
            <h4 className="icon solid">Adobe Illustrator</h4>
          </li>
          <li className="icon solid fa-paint-brush">
            <h3>Graphic Design</h3>
            <p>
              I listen carefully, develop strategy and deliver graphic design in
              a unique way with you being there in every step of the journey
            </p>
          </li>
          <li className="icon solid fa-project-diagram">
            <h3>Work methodologies</h3>
            <h4>Scrum</h4>
            <h4>Kanban</h4>
          </li>
        </ul>
      </div>
    </section>

    <section id="cta" className="wrapper style6">
      <div className="inner">
        <header>
          <h3>Do you like what you see?</h3>
          <h3>Don't wait, contact me</h3>
        </header>
        <ul className="actions stacked">
          <li>
            <a href="mailto:aleksandar.drobnjak@gmail.com" className="button fit primary">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
